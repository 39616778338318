import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

/**
 * Get access
 */
export function getAccess(auth, organization, reference, group, permission = 'write') {
  const isWrite = String(permission).toLowerCase() === 'write';
  if (!auth) {
    return  null;
  }
  // Super admin has access to all
  if (auth.role === 'administrator') {
    return 'administrator';
  }
  // Organization admin has access to all within the organization
  if (!isNil(organization)) {
    const organizationId = parseInt(organization.id || organization, 10);
    if (organizationId === auth.organization?.id && auth.organization?.user?.id === auth.id) {
      return 'organization-administrator';
    }
  }
  if (!isNil(reference)) {
    const referenceId = parseInt(reference.id || reference, 10);
    const userReference = (auth.references || []).find((ref) => (
      ref.id === referenceId
    ));
    if (userReference) {
      const referenceHigherAccess = getAccess(auth, userReference.organization, null, null, permission);
      if (referenceHigherAccess) {
        return referenceHigherAccess;
      }
      // Reference admin has access to all within main and sub groups
      if (userReference.references_users?.role === 'administrator') {
        return 'reference-administrator';
      }
      // Reference member has read access to main and sub groups
      if (!isWrite) {
        return 'reference-member';
      }
    }
  }
  // Group admin has access to all within sub groups
  if (!isNil(group)) {
    const groupId = parseInt(group.id || group, 10);
    const userGroup = (auth.groups || []).find((grp) => (
      grp.id === groupId
    ));
    if (userGroup) {
      const groupHigherAccess = getAccess(
        auth,
        userGroup.organization || userGroup.reference.organization,
        userGroup.reference,
        null,
        null,
        permission
      );
      if (groupHigherAccess) {
        return groupHigherAccess;
      }
      // Group admin has access to all within groups
      if (userGroup?.groups_users?.role === 'administrator') {
        return 'group-administrator';
      }
      // Group member has read access to sub groups
      if (!isWrite) {
        return 'group-member';
      }
    }
  }
  // No access
  return null;
}

/**
 * Use access
 */
export function useAccess(organization, reference, group, permission = 'write') {
  return getAccess(useAuth(), organization, reference, group, permission);
}

/**
 * Use auth
 */
export function useAuth() {
  const { auth } = useSelector(({ api: { user } }) => ({
    auth: user.auth
  }));
  return auth?.data || {};
}

/**
 * Use user role
 */
export function useRole(groupId) {
  const { auth } = useSelector(({ api: { user } }) => ({
    auth: user.auth
  }));
  if (isUndefined(groupId)) {
    return auth.data.role;
  }
  const group = (auth.data.groups || []).find((item) => (
    item.id === groupId
  ));
  if (!group) {
    return 'member';
  }
  return (group.groups_users || {}).role || 'member';
}


export function useGroupAdminChecker() {
  const { auth } = useSelector(({ api: { user } }) => ({
    auth: user.auth
  }));
  const { groups = [] } = auth.data || {};
  const groupAdmin = groups.find(({ groups_users = {} }) => (
    groups_users.role === 'administrator'
  ));

  return groupAdmin;
}


export function getPrice(userSubscription, machine_number, origPrice) {
  if (!isEmpty(userSubscription.data)) {
    const machine = userSubscription.data.find(({ variant = {} }) => (
      variant.machine.machine_number === machine_number
    ));
    if (machine && machine?.pricing_group?.id && machine?.pricing_group?.id > 0) {
      const price = machine.variant['pricing_group_' + machine.pricing_group.id];
      if (price > 0) {
        return price + '€/kk';
      }
    }
  }

  if (origPrice > 0 && userSubscription.data !== null) {
    return origPrice + '€/kk';
  }

  return "";
}

export function IsAnAdministrator() {
  const { email, groups, organization, references, role } = useAuth();
  if (role === 'administrator' || email === (organization?.user?.email ?? '')) {
    return true;
  } else {
    const groupRoles = compact((groups ? groups : []).map((group) => group?.groups_users?.role ?? null));
    const refRoles = compact((references ? references : []).map((ref) => ref?.references_users?.role ?? null));
    return uniq([...groupRoles, ...refRoles]).includes('administrator');
  }
}

export function GetBusinessId() {
  const { business_id } = useAuth();
  return business_id;
}
