import React, { Suspense, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import routeList, { routes } from 'routes';

import auth from 'services/auth';
import { useAuth } from 'api/user/utils';
import { useLanguage } from 'i18n';
import { fromQuery } from 'utils';
import { initialize, webview } from './actions';
import Loader from 'components/helpers/Loader';
import Main from 'components/layouts/main/Main';

/**
 * App component
 */
export default function App() {
  const dispatch = useDispatch();
  const { search = '' } = useLocation();
  const userAuth = useAuth();
  const setDefaultLanguage = useLanguage();
  const { ready } = useSelector(
    ({ app: { ready } }) => ({
      ready
    })
  );
  const userLang = userAuth?.language;
  const { token = '', app = 'false', lang } = useMemo(() => (
    fromQuery(search) || {}
  ), [search]);

  const initializeLanguage = useCallback(async (userLanguage) => {
    const language = userLanguage || await localStorage.getItem('language-session');
    if (language) {
      setDefaultLanguage(language);
    }
    if (lang) {
      setDefaultLanguage(lang);
    }
  }, [setDefaultLanguage, lang]);

  // On mount
  useEffect(() => {
    initializeLanguage(userLang);
  }, [initializeLanguage, userLang]);
  useEffect(() => {
    if (token) {
      auth.token = token;
    }
    dispatch(initialize());
  }, [dispatch, token]);
  useEffect(() => {
    if (window.ReactNativeWebView) {
      dispatch(webview(true));
    }
  }, [dispatch, token, app]);

  // App is not yet ready at this point
  if (!ready) {
    return (
      <Loader />
    );
  }
  return (
    <Main>
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes(routeList)}
        </Switch>
      </Suspense>
    </Main>
  );
}
