import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  logo: {
    objectFit: 'contain',
    paddingTop: '20px',
    width: '125px'
  },
  downloadLogo: {
    objectFit: 'contain',
    width: '250px',
    '@media (min-width: 1200px)': {
      width: '300px'
    }
  },
  loginLogo: {
    objectFit: 'contain',
    paddingTop: '53px',
    paddingBottom: '33px',
    width: '94px'
  }
})
