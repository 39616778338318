import { put, takeLatest } from 'redux-saga/effects';
import { createAction } from 'redux-actions';

import { once } from 'store';
import { join } from 'store/utils';
import { INITIALIZE } from './actions';
import { USER_AUTHORIZE, userAuthorize } from 'api/user/actions';

/**
 * Watch initialize
 */
export function* watchInitialize() {
  yield takeLatest(INITIALIZE, function* handleInitialize() {
    yield put(userAuthorize());
    yield once(join(USER_AUTHORIZE, 'COMPLETE'));
    yield put(createAction(join(INITIALIZE, 'COMPLETE'))());
  });
}

export default [
  watchInitialize
];
