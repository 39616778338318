import keyBy from 'lodash/keyBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

/**
 * Create reducers
 */
export function createReducers(action, name, options) {
  const key = (isString(options) ? options : (options || {}).key) || 'id';
  return {
    [action]: (state) => ({
      ...state,
      [name]: {
        ...state[name],
        busy: true
      }
    }),
    [join(action, 'QUERY')]: (state, { payload = {} }) => ({
      ...state,
      [name]: {
        ...state[name],
        query: payload
      }
    }),
    [join(action, 'SUCCESS')]: (state, { payload }) => {
      const result = {};
      if (isNumber(payload.count) && isArray(payload.results)) {
        result.count = payload.count;
        result.data = keyBy(payload.results, key);
      } else {
        result.count = isEmpty(payload) ? 0 : 1;
        result.data = payload;
      }
      return {
        ...state,
        [name]: {
          ...state[name],
          ...result
        }
      };
    },
    [join(action, 'FAIL')]: (state, { payload }) => {
      return {
        ...state,
        [name]: {
          ...state[name],
          error: payload
        }
      };
    },
    [join(action, 'COMPLETE')]: (state) => ({
      ...state,
      [name]: {
        ...state[name],
        busy: false
      }
    }),
    [join(action, 'RESET')]: (state) => ({
      ...state,
      [name]: emptyState()
    })
  }
}

/**
 * Create state
 */
export function createState(name, data = {}) {
  return {
    [name]: emptyState(data)
  };
}

/**
 * Empty state
 */
export function emptyState(data = {}) {
  return {
    busy: false,
    count: 0,
    data,
    error: null,
    query: null
  };
}

/**
 * Join action
 */
export function join(action, type = '') {
  return action + (type ? ('_' + type) : '');
}
