import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import Drawer from 'components/drawer/Drawer';
import Header from 'components/header/Header';
import theme from './theme';
import useStyles from './styles';

Main.propTypes = {
  children: PropTypes.element
};

/**
 * Main layout
 */
export default function Main({ children }) {
  const classes = useStyles();
  return (
    <>
      <CssBaseline classes={classes} />
      <ThemeProvider theme={theme}>
        <Header />
        <Drawer />
        {children}
      </ThemeProvider>
    </>
  );
}
