import { handleActions } from 'redux-actions';
import isUndefined from 'lodash/isUndefined';

import { join } from 'store/utils';
import {
  DRAWER_TOGGLE,
  HEADER_ASSIGN,
  USER_SUBSCRIPTION_ASSIGN,
  CONTENT_ASSIGN,
  INITIALIZE,
  DRAWER_ASSIGN,
  WEBVIEW
} from './actions';

/**
 * App state
 */
export const appState = {
  drawer: {
    open: false,
    type: 'temporary',
    active: true
  },
  header: {
    active: false,
    back: null,
    options: null,
    title: '',
    update: null
  },
  userSubscription: {
    data: null
  },
  content: {
    data: []
  },
  ready: false,
  webview: false
};

/**
 * App reducer
 */
export default handleActions({
  [DRAWER_TOGGLE]: (state, { payload }) => ({
    ...state,
    drawer: {
      ...state.drawer,
      open: isUndefined(payload) ? !state.drawer.open : payload
    }
  }),
  [DRAWER_ASSIGN]: (state, { payload = {} }) => ({
    ...state,
    drawer: {
      ...state.drawer,
      ...payload
    }
  }),
  [HEADER_ASSIGN]: (state, { payload = {} }) => ({
    ...state,
    header: {
      ...state.header,
      ...payload
    }
  }),
  [USER_SUBSCRIPTION_ASSIGN]: (state, { payload = {} }) => ({
    ...state,
    userSubscription: {
      ...state.userSubscription,
      ...payload
    }
  }),
  [CONTENT_ASSIGN]: (state, { payload = {} }) => ({
    ...state,
    content: {
      ...state.content,
      ...payload
    }
  }),
  [join(INITIALIZE, 'COMPLETE')]: (state) => ({
    ...state,
    ready: true
  }),
  [WEBVIEW]: (state, { payload = true }) => ({
    ...state,
    webview: payload
  })
},
  appState);
