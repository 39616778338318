import { makeStyles } from '@material-ui/core/styles';
import config from 'config';

/**
 * Drawer styles
 */
export default makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: 300,
    minWidth: 300
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: config.drawer.width,
      flexShrink: 0,
    },
  },
  drawerOpenPermanent: {
    marginRight: theme.spacing(3) + config.drawer.width
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .active': {
      backgroundColor: theme.palette.primary.accent
    }
  },
  logoContainer: {
    alignSelf: 'center',
    paddingTop: '10px'
  },
  space: {
    marginBottom: '20px'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  name: {
    paddingTop: 30,
    paddingLeft: 20,
  },
  itemLoginContainer: {
    position: 'absolute',
    bottom: '30px',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      bottom: '0',
    },
  },
  itemLanguageContainer: {
    position: 'absolute',
    bottom: '75px',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      bottom: '0',
    },
  },
  selected: {
    fontWeight: 'bold'
  },
  downloads: {
    padding: '0 16px',
    '& a': {
      display: 'inline-block',
      marginBottom: '10px',
      '&:last-of-type': {
        marginBottom: '0'
      }
    },
    '& img': {
      maxWidth: '205px',
    },
  },
}));
