import { bind } from 'utils';
import API from '../API';

/**
 * Organization API
 */
export default class Organization {

  constructor() {
    this.api = new API('organization');
    bind(this, [
      'deleteAdmin',
      'getOrganizations',
      'putAdmin'
    ]);
  }

  /**
   * Remove admin
   */
  deleteAdmin(id) {
    return this.api.delete(`organizations/${id}/admin`);
  }

  /**
   * Get organizations
   */
  getOrganizations(query) {
    return this.api.navigate(`organizations`, query);
  }

  /**
   * Set admin
   */
  putAdmin({ id, ...inputs }) {
    return this.api.put(`organizations/${id}/admin`, inputs);
  }
}
