import React from 'react';
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress'

Loader.propTypes = {
  color: PropTypes.string
};

Loader.defaultProps = {
  color: 'primary'
};
/**
 * Loader component
 */
export default function Loader({ color }) {
  return (
    <Box style={{ textAlign: 'center' }}>
      <CircularProgress color={color}/>
    </Box>
  );
}
