import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { usePostMessage } from 'utils';
import { useRole } from 'routes';
import { drawerToggle } from 'components/app/actions';
import useStyles from './styles';

Header.propTypes = {
  isHome: PropTypes.bool
};

export default function Header({ isHome = false }) {
  const styles = useStyles();
  const postMessage = usePostMessage();
  const role = useRole();
  const logo = '/assets/images/logo.png';

  const dispatch = useDispatch();

  const toggleDrawer = useCallback(() => {
    if (role === 'guest' || !postMessage({ action: 'toggleMenu' })) {
      dispatch(drawerToggle());
    }
  }, [dispatch, postMessage, role]);

  return (
    <Grid item xs={12} className={styles.gridItem}>
      <IconButton
        className={styles.menuBtn}
        edge="start"
        onClick={toggleDrawer}
      >
        <Icon>menu</Icon>
        <span className="alt-text">{`Menu`}</span>
      </IconButton>
      {isHome ? <IconButton
        className={styles.menuBtn + ' ' + styles.menuBtnAlternate}
        edge="start"
        onClick={toggleDrawer}
      >
        <Icon>menu</Icon>
        <span className="alt-text">{`Menu`}</span>
      </IconButton> :
        <>
          <IconButton
            className={styles.menuBtn}
            edge="start"
            onClick={toggleDrawer}
          >
            <Icon>menu</Icon>
            <span className="alt-text">{`Menu`}</span>
          </IconButton>
          <img
            src={logo}
            alt="hako logo"
            className={styles.hakoLogo}
          />
        </>
      }
    </Grid>
  )
}
