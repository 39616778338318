import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18next';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { useI18n, useLanguage } from 'i18n';
import { user as api } from 'api';
import { usePostMessage } from 'utils';
import { drawerToggle } from 'components/app/actions';
import Logo from 'components/helpers/Logo';
import { redirect } from 'routes';
import useStyles from './styles';
import { appStoreLink, playStoreLink } from 'components/views/download/DownloadApp';

const menuMap = [
  {
    name: 'Home',
    icon: 'home',
    link: '/',
    roles: [
      'guest',
      'administrator',
      'member'
    ]
  },
  {
    name: 'My account',
    icon: 'person',
    link: '/admin/account',
    roles: [
      'member'
    ]
  },
  {
    name: 'Manage customers',
    icon: 'account_box',
    link: '/admin/customers',
    roles: [
      'administrator'
    ]
  },
  {
    name: 'Manage groups',
    icon: 'supervisor_account',
    link: '/admin/groups',
    roles: [
      'organization-administrator',
      'reference-administrator',
      'group-administrator',
      'member'
    ]
  },
  {
    name: 'Manage users',
    icon: 'account_circle',
    link: '/admin/users',
    roles: [
      'administrator',
      'organization-administrator',
      'reference-administrator',
      'group-administrator'
    ]
  },
  {
    name: 'Machines',
    icon: 'build',
    link: '/admin/machines',
    roles: [
      'organization-administrator',
      'reference-administrator',
      'group-administrator',
      'member'
    ]
  },
  {
    name: 'Service and equipment requests',
    icon: 'local_mall',
    link: '/admin/orders',
    roles: [
      'organization-administrator',
      'reference-administrator',
      'group-administrator'
    ]
  },
  {
    name: 'Manage registrations',
    icon: 'group_add',
    link: '/admin/registrations',
    roles: []
  }
];

const languageMap = [
  {
    language: 'Suomeksi',
    code: 'fi'
  },
  {
    language: 'English',
    code: 'en'
  },
  {
    language: 'Svenska',
    code: 'se'
  }
];

/**
 * Drawer Items
 */
export default function Items() {
  const t = useI18n();
  const styles = useStyles();
  const dispatch = useDispatch();
  const setLanguage = useLanguage();
  const postMessage = usePostMessage();
  const { auth, webview } = useSelector(({ api: { user }, app: { webview } }) => ({
    auth: user.auth,
    webview
  }));
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const filterPaths = useCallback((paths) => {
    const { id, groups, organization, references, role = 'guest' } = auth.data || {};
    const isOrgAdmin = id && organization?.user?.id === id;
    const isReferenceAdmin = !!(references || []).find((reference) => (
      reference?.references_users.role === 'administrator'
    ));
    const isGroupAdmin = !!(groups || []).find((group) => (
      group?.groups_users.role === 'administrator'
    ));
    return paths.filter((path) => {
      const roles = path.roles || [];
      if (roles.includes(role)) {
        return true;
      }
      if (isOrgAdmin && roles.includes('organization-administrator')) {
        return true;
      }
      if (isReferenceAdmin && roles.includes('reference-administrator')) {
        return true;
      }
      if (isGroupAdmin && roles.includes('group-administrator')) {
        return true;
      }
      return false;
    });
  }, [auth.data]);

  const navigateApp = useCallback((nav, params) => {
    postMessage({
      action: 'navigate',
      args: [
        nav, params
      ]
    });
  }, [postMessage]);

  const handleChangeLanguage = useCallback(async (lang) => {
    setLanguage(lang);
    if (webview) {
      navigateApp('language', lang);
    }
    if (auth.data?.id) {
      await api.putMe({ language: lang });
    }
  }, [auth.data, setLanguage, webview, navigateApp]);

  const goTo = useCallback((to) => {
    redirect(to);
    dispatch(drawerToggle(false));
  }, [dispatch]);

  return (
    <div
      className={styles.itemContainer}
    >
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <Typography
        color="secondary"
        variant="h6"
        className={styles.name}
      >
        {auth.data.name || ''}
      </Typography>
      <div className={styles.space} />
      {[menuMap].map((paths, index) => (
        <List key={`li-${index}`}>
          {filterPaths(paths).map((path) =>
            <ListItem
              onClick={() => goTo(path.link)}
              button
              exact="false"
              key={`li-item-${path.name}`}>
              <ListItemIcon>
                <Icon color="secondary">
                  {path.icon}
                </Icon>
              </ListItemIcon>
              <ListItemText disableTypography>
                <Box
                  fontWeight="700"
                >
                  <Typography
                    color="secondary"
                    variant="body2"
                  >
                    {t(path.name)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
          )}
        </List>
      ))}
      <div className={styles.space} />
      {!webview &&
        <>
          <div className={styles.downloads}>
            <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
              <img alt="Get it on Google Play" src="/assets/images/google-play-store.png" />
            </a>
            <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
              <img alt="Download on the App Store" src="/assets/images/app-store-us.png" />
            </a>
          </div>
          <div className={styles.space} />
        </>
      }
      <div className={styles.itemContainer + ' ' + styles.itemLanguageContainer}>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <Icon color="secondary">
              language
            </Icon>
          </ListItemIcon>
          <ListItemText disableTypography>
            <Box
              fontWeight="700"
            >
              <Typography
                color="secondary"
                variant="body2"
              >
                {t('Language')}
              </Typography>
            </Box>
          </ListItemText>
          {open ? <Icon color="secondary">
            expand_less
            </Icon> : <Icon color="secondary">
              expand_more
            </Icon>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {languageMap.map((lang, index) => (
              <ListItem key={`li-lang-${index}`} button onClick={() => handleChangeLanguage(lang?.code)}>
                <ListItemText disableTypography>
                  <Box
                    fontWeight="700"
                  >
                    <Typography
                      color="secondary"
                      variant={(i18n.language === lang?.code) ? 'body1' : 'body2'}
                    >
                      {lang.language}
                    </Typography>
                  </Box>
                </ListItemText>
              </ListItem>

            ))}
          </List>
        </Collapse>
      </div>
      <div className={styles.itemContainer + ' ' + styles.itemLoginContainer}>
        {(isEmpty(auth.data) ?
          (webview ?
            <ListItem
              onClick={() => navigateApp('login', '')}
              button
            >
              <ListItemIcon>
                <Icon color="secondary">
                  lock_open
                </Icon>
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  color="secondary"
                  variant="body2"
                >
                  {t('Log in')}
                </Typography>
              </ListItemText>
            </ListItem>
            :
            <ListItem
              component={NavLink}
              to="/auth/login"
              button
            >
              <ListItemIcon>
                <Icon color="secondary">
                  lock_open
                </Icon>
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  color="secondary"
                  variant="body2"
                >
                  {t('Log in')}
                </Typography>
              </ListItemText>
            </ListItem>) :
          (webview ?
            <ListItem
              onClick={() => navigateApp('logout', '')}
              button
            >
              <ListItemIcon>
                <Icon color="secondary">
                  lock_open
                </Icon>
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  color="secondary"
                  variant="body2"
                >
                  {t('Log out')}
                </Typography>
              </ListItemText>
            </ListItem> :
            <ListItem
              component={NavLink}
              to="/auth/logout"
              button
            >
              <ListItemIcon>
                <Icon color="secondary">
                  lock_open
                </Icon>
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  color="secondary"
                  variant="body2"
                >
                  {t('Log out')}
                </Typography>
              </ListItemText>
            </ListItem>

          )
        )}
      </div>
    </div >
  );
}
