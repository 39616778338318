import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    flex: 1,
    height: 'auto'
  },
  avatar: {
    width: '150px',
    objectFit: 'contain'
  },
  logoContainer: {
    '&.MuiGrid-item': {
      paddingTop: '70px',
      paddingBottom: '50px'
    }
  },
  gridContainer: {
    width: '100%',
    overflow: 'hidden'
  }
}));
