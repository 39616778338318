import core from './core/sagas';
import group from './group/sagas';
import location from './location/sagas';
import machine from './machine/sagas';
import user from './user/sagas';

export default [
  ...core,
  ...group,
  ...location,
  ...machine,
  ...user
];
