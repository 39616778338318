import { bind } from 'utils';
import API from '../API';

/**
 * Order API
 */
export default class Order {

  constructor() {
    this.api = new API('order');
    bind(this, [
      'getOrder',
      'getOrders',
      'putOrders',
      'postOrder'
    ]);
  }

  /**
   * Get single order
   */
  getOrder({ id }) {
    return this.api.get(`orders/${id}`);
  }

  /**
   * Get orders
   */
  getOrders(query) {
    return this.api.navigate('orders', query);
  }

  /**
   * Put orders
   */
  putOrders(id, query) {
    return this.api.put(`orders/${id}`, query);
  }

  /**
   * Create order
   */
  postOrder(data) {
    return this.api.post('orders', data);
  }
}
