import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';

import { fromQuery } from 'utils';
import { watch } from 'store';
import { useAuth } from 'api/user/utils';
import routeList from './routes';
import authApi from 'services/auth';

/**
 * Browser history
 */
export const history = createBrowserHistory();

/**
 * Account page route
 */
export const accountPage = '/admin/account';

/**
 * Guards with fallback
 */
export const guards = {
  guest: '/',
  member: '/auth/login',
  admin: '/auth/login'
};

/**
 * On route change
 */
export function onRouteChange(callback) {
  const watcher = watch(['@@router/LOCATION_CHANGE'], callback);
  return () => {
    watcher.cancel();
  };
}

/**
 * Redirect
 */
export function redirect(path, replace = false) {
  return history[replace ? 'replace' : 'push'](path);
}

/**
 * Get route
 */
export function route(item) {
  if (!item.component) {
    item = {
      component: item,
    };
  }
  const routes = (item.routes || []).map((subroute) => {
    const subpath = trimStart(subroute.path, '/'),
      path = trimEnd(item.path, '/') + (subpath ? ('/' + subpath) : '');
    return {
      ...subroute,
      path
    };
  });
  return (
    <Route
      key={item.path}
      exact={item.exact !== false}
      path={item.path}
      render={(props) => (
        <item.component
          {...props}
          {...item.props}
          route={omit(item, [
            'component',
            'routes'
          ])}
          routes={routes}
        />
      )}
    />
  );
}

/**
 * Route query
 */
export function routeQuery({ location: { pathname, search } }) {
  return {
    path: pathname,
    query: fromQuery(search)
  };
}

/**
 * Get routes
 */
export function routes(list) {
  return list.map(route);
}

/**
 * Use guard
 */
export function useGuard(guard, fallback, pathname) {
  const auth = useAuth();
  const role = auth.role || 'guest';
  useEffect(() => {
    if (isUndefined(guards[guard])) {
      return;
    }
    if (guard === 'guest' && role === guard) {
      return;
    }
    if (!auth.phone && pathname !== accountPage) {
      redirect(accountPage);
      return;
    }
    if (guard === 'member' && role !== 'guest') {
      return;
    }
    if (guard === 'admin' && role === 'administrator') {
      return;
    }
    if (!authApi.token) {
      redirect(fallback || guards[guard]);
    }
  }, [auth, role, fallback, guard, pathname]);
}

/**
 * Get current user's role
 */
export function useRole() {
  const { role } = useAuth();
  return role || 'guest';
}

/**
 * Use tab
 */
export function useTab({ onMount, route }) {
  useEffect(() => {
    if (route.tab) {
      onMount(route.tab);
    }
  }, [route.tab, onMount]);
}

export default routeList;
