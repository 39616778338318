import { bind } from 'utils';
import API from '../API';
import Factory from './Factory';

/**
 * Group API
 */
export default class Group {

  constructor() {
    this.api = new API('group');
    this.factory = new Factory(this.api, {
      count: 20
    });
    bind(this, [
      'deleteGroup',
      'getGroup',
      'getGroups',
      'postGroup',
      'putGroup'
    ]);
  }

  /**
   * Delete group
   */
  deleteGroup(id) {
    return this.api.delete(`groups/${id}`);
  }

  /**
   * Get single group
   */
  getGroup(id) {
    return this.api.get(`groups/${id}`);
  }

  /**
   * Get groups
   */
  getGroups({ reference, ...query }) {
    if (reference) {
      return this.api.navigate(`references/${reference}/groups`, query);
    } else {
      return this.api.navigate('groups', query);
    }
  }

  /**
   * Post group
   */
  postGroup(inputs) {
    return this.api.post('groups', inputs);
  }

  /**
   * Put Group
   */
  putGroup({ id, ...inputs }) {
    return this.api.put(`groups/${id}`, inputs);
  }
}
