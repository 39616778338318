import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import range from 'lodash/range';
import remove from 'lodash/remove';

import { delay } from 'utils';

const DELAY = 400;

/**
 * Base Factory for data seeding
 */
export default class Factory {

  static instances = {};

  constructor(api, options = {}) {
    this.api = api;
    this.options = {
      count: 100,
      ...options
    };
    this.key = `hako-${this.api.service}`;
    this.data = JSON.parse(window.localStorage.getItem(`${this.key}-data`) || '{}');
    this.index = JSON.parse(window.localStorage.getItem(`${this.key}-index`) || '{}');
    this.constructor.instances[this.api.service] = this;
  }

  /**
   * Create
   */
  async create(type, data) {
    await this.delay();
    this.data[type].push({
      ...data,
      id: ++this.index[type]
    });
  }

  /**
   * Delay
   */
  async delay() {
    await delay(DELAY);
  }

  /**
   * Delete
   */
  async delete(type, filter) {
    await this.delay();
    filter = this.getFilter(filter);
    this.onDelete(type, 'before', filter);
    remove(this.seed(type), filter);
  }

  /**
   * Filter
   * @params
   * type - string
   * data - object
   * query - object
   */
  filter() {
    return true;
  }

  /**
   * Generate single data
   * @params
   * type - string
   */
  generate() {
    throw new Error('generate method must be overridden');
  }

  /**
   * Get single
   */
  async get(type, filter) {
    await this.delay();
    return this.seed(type).find(
      this.getFilter(type, filter)
    );
  }

  /**
   * Get factory
   */
  getFactory(service) {
    return this.constructor.instances[service];
  }

  /**
   * Get filter
   */
  getFilter(type, filter) {
    if (isObject(filter)) {
      const object = { ...filter };
      return (item) => {
        return this.filter(type, item, object);
      };
    } else if (!isFunction(filter)) {
      const id = parseInt(filter, '0');
      return (item) => (
        item.id === id
      );
    }
  }

  /**
   * Navigate
   */
  async navigate(type, query = {}) {
    const limit = query.limit || 20,
          page = query.page || 1,
          start = (page - 1) * limit;
    const filtered = this.seed(type).filter(
      (item) => this.filter(type, item, query)
    );
    await this.delay();
    return {
      count: filtered.length,
      results: filtered.slice(start, start + limit)
    };
  }

  /**
   * On delete
   */
  onDelete() {
    // Do nothing
  }

  /**
   * On seed
   * Can be overidden
   */
  onSeed() {
    // Do nothing
  }

  /**
   * Seed data
   */
  seed(type) {
    if (this.data[type]) {
      return this.data[type];
    }
    if (isUndefined(this.index[type])) {
      this.index[type] = 0;
    }
    this.data[type] = range(this.options.count).map(
      () => this.generate(type, ++this.index[type])
    );
    window.localStorage.setItem(`${this.key}-index`, JSON.stringify(this.index));
    window.localStorage.setItem(`${this.key}-data`, JSON.stringify(this.data));
    this.onSeed(type, 'after', this.data[type]);
    return this.data[type];
  }
}
