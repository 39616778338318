import { handleActions } from 'redux-actions';

// import { createReducers, createState } from 'store/utils';

/**
 * Core state
 */
export const coreState = {

};

/**
 * Core reducer
 */
export default handleActions({

},
coreState);
