import { handleActions } from 'redux-actions';

// import { createReducers, createState } from 'store/utils';

/**
 * Group state
 */
export const groupState = {

};

/**
 * Group reducer
 */
export default handleActions({

},
groupState);
