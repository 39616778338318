import Core from './core/Core';
import Group from './group/Group';
import Location from './location/Location';
import Machine from './machine/Machine';
import Organization from './organization/Organization';
import User from './user/User';
import Subscription from './subscription/Subscription';
import Order from './order/Order';

export const core = new Core();
export const group = new Group();
export const location = new Location();
export const machine = new Machine();
export const organization = new Organization();
export const user = new User();
export const subscription = new Subscription();
export const order = new Order();
