import Auth from '@aws-amplify/auth';

import { bind } from 'utils';
import Listener from 'services/core/Listener';

/**
 * Authentication service
 */
export default class Authentication extends Listener {

  constructor(config) {
    super();
    this.config = config;
    this.token = null;
    this.user = null;
    bind(this, [
      'completePassword',
      'destroy',
      'forgotPassword',
      'forgotPasswordSubmit',
      'login',
      'logout',
      'refresh'
    ]);
    this.init();
  }

  /**
   * Complete password
   */
  async completePassword({ user, password }) {
    const response = await Auth.completeNewPassword(user, password);
    // Clear session and re-login
    await this.logout();
    await this.login({
      username: user.username || user,
      password
    });
    return response;
  }

  /**
   * Destroy token
   */
  destroy() {
    this.fire('destroy', [this.token = this.user = null]);
  }

  /**
   * Initialize
   */
  init() {
    Auth.configure({
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      cookieStorage: {
        ...this.config.cookie,
        path: '/',
        expires: 30
      },
      region: this.config.cognito.region,
      userPoolId: this.config.cognito.pool_id,
      userPoolWebClientId: this.config.cognito.app_id
    });
  }

  /**
   * Forgot password
   */
  forgotPassword(username) {
    return Auth.forgotPassword(username);
  }

  /**
   * Submit forgot password
   */
  forgotPasswordSubmit({ username, code, password }) {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  /**
   * Login
   */
  login(inputs) {
    return Auth.signIn(inputs);
  }

  /**
   * Logout
   */
  async logout() {
    await Auth.signOut();
    this.destroy();
  }

  /**
   * Refresh user
   */
  async refresh() {
    try {
      if (!this.token) {
        const session = await Auth.currentSession();
        this.token = session.getAccessToken().getJwtToken();
        this.user = session.accessToken?.payload?.username;
      }
      this.fire('refresh', [
        this.token,
        this.user
      ]);
    } catch (exception) {
      this.destroy();
      return Promise.reject(exception);
    }
  }
}
