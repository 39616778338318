import { bind } from 'utils';
import API from '../API';
import Factory from './Factory';

/**
 * Location API
 */
export default class Location {

  constructor() {
    this.api = new API('location');
    this.factory = new Factory(this.api);
    bind(this, [
      'deleteLocation',
      'getLocation',
      'getLocations',
      'postLocation'
    ]);
  }

  /**
   * Delete location
   */
  deleteLocation({ group, id }) {
    return this.api.delete(`groups/${group}/locations/${id}`);
  }

  /**
   * Get single location
   */
  getLocation({ group, id }) {
    return this.api.navigate(`groups/${group}/locations/${id}`);
  }

  /**
   * Get locations
   */
  getLocations({ group, ...query }) {
    if (!group) {
      return this.api.navigate(`locations`, query);
    } else {
      return this.api.navigate(`groups/${group}/locations`, query);
    }
  }

  /**
   * Post location
   */
  postLocation({ group, ...inputs }) {
    return this.api.post(`groups/${group}/locations`, inputs);
  }
}
