import faker from 'faker';
import random from 'lodash/random';
import sample from 'lodash/sample';
import upperFirst from 'lodash/upperFirst';

import Factory from 'services/core/Factory';

/**
 * Machine factory
 */
export default class Machine extends Factory {

  /**
   * Delete
   */
  async delete() {
    throw new Error('Not possible to delete machines');
  }

  /**
   * Filter
   */
  filter(type, machine, query = {}) {
    switch (type) {
      case 'machines':
        if (query.id) {
          return machine.id === parseInt(query.id || '0');
        } else if (query.location) {
          return (machine.location || {}).id === parseInt(query.location || '0');
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  /**
   * Generate single data
   */
  generate(type, id) {
    const location = sample(this.getFactory('location').seed('locations'));
    switch (type) {
      case 'machines':
        location.machines++;
        return {
          id,
          name: upperFirst(faker.lorem.words(random(3, 5))),
          serial: faker.random.uuid(),
          location
        };
      default:
        return {};
    }
  }
}
