import { useCallback } from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import { sprintf } from 'utils';
import fi from './fi.json';
import en from './en.json';
import se from './se.json';

i18n
  .use(initReactI18next)
  .init({
    keySeparator: false,
    resources: {
      fi: {
        translation: fi
      },
      en: {
        translation: en
      },
      se: {
        translation: se
      }
    },
    fallbackLng: 'fi',
    lng: 'fi'
  });

export default i18n;

/**
 * Use i18n
 */
export function useI18n() {
  const { t } = useTranslation();
  return useCallback((text, ...args) => {
    return sprintf(t(text), ...args);
  }, [t]);
}

/**
 * Change Language
 */
export function useLanguage() {
  return useCallback((language) => {
    localStorage.setItem('language-session', language);
    i18n.changeLanguage(language);
  }, []);
}
