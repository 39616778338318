import { makeStyles } from '@material-ui/core/styles';
import config from 'config';

/**
 * Header styles
 */
export default makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up(config.breakpoints.desktop.width)]: {
      backgroundColor: theme.palette.secondary.main,
      paddingTop: '30px'
    }
  },
  appBarShift: {
    [theme.breakpoints.up(config.breakpoints.desktop.width)]: {
      paddingLeft: theme.spacing(3) + 300
    }
  },
  iconBtn: {
    color: theme.palette.secondary.main,
    padding: '5px',
    [theme.breakpoints.up(config.breakpoints.desktop.width)]: {
      color: theme.palette.primary.main,
      alignSelf: 'center',
      padding: '15px',
    }
  },
  menuBtn: {
    color: theme.palette.secondary.main,
    pointerEvents: 'auto',
    [theme.breakpoints.up(config.breakpoints.desktop.width)]: {
      display: 'none',
      pointerEvents: 'none'
    }
  },
  title: {
    flexGrow: 1,
    padding: '0 36px 0 0',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up(config.breakpoints.desktop.width)]: {
      color: theme.palette.primary.main,
      textAlign: 'left'
    }
  },
  withOptions: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  withUpdate: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'left',
    '& span': {
      flexGrow: 1
    }
  }
}));
