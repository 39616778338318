import { makeStyles } from '@material-ui/core/styles';
import config from 'config';

import { fontWeight } from 'components/layouts/main/theme'

/**
 * Header styles
 */
export default makeStyles((theme) => ({
  gridItem: {
    width: '90%',
    [`@media (min-width: ${config.breakpoints.laptop.width}px)`]: {
      width: 1440,
      paddingLeft: 16,
      paddingRight: 16,
    }
  },
  hakoLogo: {
    display: 'flex',
    width: '65px',
    height: '65px',
    margin: '20px auto 0px auto',
    [`@media (min-width: ${config.breakpoints.laptop.width}px)`]: {
      margin: '20px auto 10px auto',
    }
  },
  menuBtn: {
    color: theme.palette.secondary.main,
    pointerEvents: 'auto',
    position: 'absolute',
    marginTop: '23px',
    zIndex: '10',
    '& .alt-text': {
      fontSize: '0'
    },
    [`@media (min-width: ${config.breakpoints.laptop.width}px)`]: {
      margin: '20px auto 10px auto',
      '& .MuiIcon-root': {
        fontSize: '36px'
      },
      '& .alt-text': {
        textTransform: 'uppercase',
        fontWeight: fontWeight.bold,
        fontSize: '24px',
        marginLeft: '8px'
      },
    }
  },
  menuBtnAlternate: {
    color: theme.palette.primary.main
  },
}));
