import remove from 'lodash/remove';

import Factory from 'services/core/Factory';

/**
 * Group factory
 */
export default class Group extends Factory {

  /**
   * Generate single data
   */
  generate(type, id) {
    switch (type) {
      case 'groups':
        return {
          id,
          locations_count: 0,
          name: String(id),
          users_count: 0
        };
      default:
        return {};
    }
  }

  /**
   * On delete
   */
  onDelete(type, hook, filter) {
    switch (type) {
      case 'groups':
        switch (hook) {
          case 'before':
            const group = this.getFactory('group').seed('groups').find(filter);
            if (group) {
              // Find all locations/users that has the group
              this.getFactory('location').seed('locations').forEach((location) => {
                remove(location.groups || [],
                  (groupId) => (groupId === group.id)
                );
              });
              this.getFactory('user').seed('users').forEach((user) => {
                remove(user.groups || [],
                  (groupId) => (groupId === group.id)
                );
              });
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * On seed
   */
  onSeed(type, hook) {
    switch (type) {
      case 'groups':
        switch (hook) {
          case 'after':
            // Ensure that seeding of users and locations are done
            this.getFactory('location').seed('locations');
            this.getFactory('user').seed('users');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}
