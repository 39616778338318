import get from 'lodash/get';
import isString from 'lodash/isString';

/**
 * Exception
 */
export default class Exception {

  constructor(error) {
    this.error = error;
  }

  /**
   * Exception message
   */
  get message() {
    if (isString(this.error)) {
      return this.error;
    }
    return get(this.error, 'response.data.exception.message') ||
      get(this.error, 'message') ||
      'Unknown error occurred';
  }

  /**
   * Catch an error
   */
  static catch(error) {
    return new this(error);
  }

  /**
   * To object
   */
  toObject() {
    return {
      message: this.message
    };
  }
}
