import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

export const logoSrc = {
  'download': '/assets/images/download-logo.png',
  'normal': '/assets/images/logo@2x.png',
  'login': '/assets/images/logo@2x.png',
};

Logo.propTypes = {
  type: PropTypes.string,
  styleName: PropTypes.string
};

Logo.defaultProps = {
  type: 'normal'
};

export default function Logo({ type, styleName }) {
  const styles = useStyles();

  return (
    <img
      src={logoSrc[type]}
      alt="hako logo"
      className={clsx(((type === 'download') ? styles.downloadLogo : (type === 'login') ? styles.loginLogo : styles.logo), styleName)}
    />
  )
}
