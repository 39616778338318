import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import MuiDrawer from '@material-ui/core/Drawer';

import { drawerToggle } from 'components/app/actions';
import config from 'config';
import useStyles from './styles';
import { changeType, remove, showDrawer } from './utils';
import Items from './Items';

/**
 * Drawer component
 */
export default function Drawer() {
  const { location } = useHistory();
  const byPass = location.pathname.includes('/products/') || location.pathname === '/' || location.pathname === '/download';
  const dispatch = useDispatch();
  const styles = useStyles();
  const { drawer } = useSelector(
    ({ app: { drawer } }) => ({
      drawer
    })
  );

  const { open, active, type } = drawer;

  const toggleDrawer = useCallback((status) => {
    dispatch(drawerToggle(status));
  }, [dispatch]);

  /**
   * Set drawer to permanent when using desktop
   */
  const adjustDrawer = useCallback(() => {
    const { clientWidth } = document.documentElement;

    if (byPass) {
      changeType('temporary');
    }
    else if ((clientWidth >= config.breakpoints.desktop.width) && type !== 'permanent') {
      changeType('permanent');
      toggleDrawer(true);
    }
    else if ((clientWidth < config.breakpoints.desktop.width) && type === 'permanent') {
      changeType('temporary');
      toggleDrawer();
    }
  }, [toggleDrawer, type, byPass]);

  const onScreenResize = useCallback(() => {
    adjustDrawer();
  }, [adjustDrawer]);

  useEffect(() => {
    adjustDrawer();
  }, [adjustDrawer]);

  useEffect(() => {
    if (!location.pathname.includes('/admin') &&
        !location.pathname.includes('/products/') &&
        location.pathname !== '/' &&
        location.pathname !== '/download') {
      remove();
    } else {
      showDrawer();
    }
    toggleDrawer(false);
  }, [location.pathname, toggleDrawer])

  useEffect(() => {
    window.addEventListener('resize', onScreenResize);
    return () => {
      window.removeEventListener('resize', onScreenResize);
    };
  }, [onScreenResize]);

  /**
   * Close drawer on backdrop click
   */
  const onBackdropClick = () => {
    dispatch(drawerToggle(false));
  };

  /**
   * Set item props later
   */
  const items = (
    <Items />
  )

  return (
    (active) ?
      <>
        <Hidden lgUp>
          <MuiDrawer
            open={open}
            ModalProps={{
              onBackdropClick
            }}
            PaperProps={{
              className: styles.paper
            }}
            className={styles.drawer}
            variant="temporary"
          >
            {items}
          </MuiDrawer>
        </Hidden>
        {type === 'permanent' ?
          <Hidden smDown>
            <MuiDrawer
              variant="permanent"
              open
              PaperProps={{
                className: styles.paper
              }}
            >
              {items}
            </MuiDrawer>
          </Hidden> : <></>
        }
        {byPass ?
          <MuiDrawer
            open={open}
            ModalProps={{
              onBackdropClick
            }}
            PaperProps={{
              className: styles.paper
            }}
            className={styles.drawer}
            variant="temporary"
          >
            {items}
          </MuiDrawer> : <></>
        }
      </> : <></>
  );
}
