import faker from 'faker';
import random from 'lodash/random';
import remove from 'lodash/remove';
import sampleSize from 'lodash/sampleSize';
import upperFirst from 'lodash/upperFirst';

import Factory from 'services/core/Factory';

/**
 * Location factory
 */
export default class Location extends Factory {

  /**
   * Delete location
   */
  async delete(type, filter) {
    await this.delay();
    switch (type) {
      case 'locations':
        const locations = this.seed('locations');
        const location = locations.find(this.getFilter(type, filter));
        if (location) {
          const groupId = parseInt(filter.group || '0');
          if (groupId) {
            const group = this.getFactory('group').seed('groups').find((item) => (
              item.id === groupId
            ));
            if (group) {
              remove(location.groups, (id) => (
                id === groupId
              ));
              group.locations_count--;
            }
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * Filter
   */
  filter(type, location, query = {}) {
    switch (type) {
      case 'locations':
        if (query.id) {
          return location.id === parseInt(query.id || '0');
        } else if (query.group) {
          return (location.groups || []).includes(parseInt(query.group || '0'));
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  /**
   * Generate single data
   */
  generate(type, id) {
    const groups = sampleSize(this.getFactory('group').seed('groups'), random(1, 5));
    switch (type) {
      case 'locations':
        return {
          id,
          name: upperFirst(faker.lorem.words(random(3, 5))),
          address: [
            'streetAddress',
            'city',
            'country',
          ].map(
            (method) => faker.address[method]()
          ).join(', '),
          groups: groups.map((group) => {
            group.locations_count++;
            return group.id;
          }),
          machines: 0,
          users: 0
        };
      default:
        return {};
    }
  }

  /**
   * On seed
   */
  onSeed(type, hook) {
    switch (type) {
      case 'locations':
        switch (hook) {
          case 'after':
            // Ensure that seeding of machines is done
            this.getFactory('machine').seed('machines');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}
