import { createAction } from 'redux-actions';

export const APP_CLICK = 'APP_CLICK';
export const CONTENT_ASSIGN = 'CONTENT_ASSIGN';
export const DRAWER_ASSIGN = 'DRAWER_ASSIGN';
export const DRAWER_TOGGLE = 'DRAWER_TOGGLE';
export const HEADER_ASSIGN = 'HEADER_ASSIGN';
export const INITIALIZE = 'INITIALIZE';
export const USER_SUBSCRIPTION_ASSIGN = 'USER_SUBSCRIPTION_ASSIGN';
export const WEBVIEW = 'WEBVIEW';

export const appClick = createAction(APP_CLICK);
export const contentAssign = createAction(CONTENT_ASSIGN);
export const drawerAssign = createAction(DRAWER_ASSIGN);
export const drawerToggle = createAction(DRAWER_TOGGLE);
export const headerAssign = createAction(HEADER_ASSIGN);
export const initialize = createAction(INITIALIZE);
export const userSubscriptionAssign = createAction(USER_SUBSCRIPTION_ASSIGN);
export const webview = createAction(WEBVIEW);
