import { makeStyles } from '@material-ui/core/styles';
import { colors } from './theme';

export const growVertically = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
};

/**
 * Global styles
 */
export default makeStyles({
  '@global': {
    html: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%'
    },
    body: {
      color: colors.grey,
      ...growVertically
    },
    '#root': {
      ...growVertically
    },
    '.MuiPaper-root': {
      color: colors.grey,
    }
  }
});
