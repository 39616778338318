import { bind, toQuery } from 'utils';
import API from '../API';

/**
 * Subscription API
 */
export default class Subscription {

  constructor() {
    this.api = new API('subscription');
    bind(this, [
      'cancelSubscription',
      'getReferenceNumbers',
      'getReference',
      'getReferences',
      'getSubscriptions',
      'getSubscription',
      'putReference'
    ]);
  }

  /**
   * Cancel subscription
   */
  cancelSubscription({ reference }) {
    return this.api.delete(`subscriptions/${reference}`);
  }

  /**
   * Get reference numbers
   */
  getReferenceNumbers(query = {}) {
    return this.api.get('reference-numbers' + toQuery(query));
  }

  /**
   * Get reference
   */
  getReference(id) {
    return this.api.get(`references/${id}`);
  }

  /**
   * Get references
   */
  getReferences(query = {}) {
    return this.api.get('references' + toQuery(query));
  }

  /**
   * Get subscriptions
   */
  getSubscriptions({ user, location, query }) {
    if (user) {
      return this.api.navigate(`users/${user}/subscriptions`, query);
    } else if (location) {
      return this.api.navigate(`locations/${location}/subscriptions`, query);
    } else {
      return this.api.navigate(`subscriptions`, query);
    }
  }

  /**
   * Get subscription
   */
  getSubscription({ location, reference }) {
    if (location) {
      return this.api.navigate(`locations/${location}/subscriptions/${reference}`);
    } else {
      return this.api.navigate(`subscriptions/${reference}`);
    }
  }

  /**
   * Put Reference
   */
  putReference({ id, ...inputs }) {
    return this.api.put(`references/${id}`, inputs);
  }
}
