import { bind } from 'utils';
import API from '../API';

/**
 * Core API
 */
export default class Core {

  constructor() {
    this.api = new API('core');
    bind(this, [

    ]);
  }
}
