import { handleActions } from 'redux-actions';

// import { createReducers, createState } from 'store/utils';

/**
 * Machine state
 */
export const machineState = {

};

/**
 * Machine reducer
 */
export default handleActions({

},
machineState);
