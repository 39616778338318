import { drawerAssign } from 'components/app/actions';
import store from 'store';

/**
 * Assign
 */
export function assign(data = {}) {
  store.dispatch(drawerAssign(data));
}

/**
 * Change drawer type
 */
export function changeType(type) {
  assign({
    type: type
  });
}

/**
 * Hide drawer
 */
export function hideDrawer() {
  assign({
    open: false
  });
}

/**
 * Remove drawer
 */
export function remove() {
  assign({
    active: false
  });
}

/**
 * Show drawer
 */
export function showDrawer() {
  assign({
    active: true
  });
}
