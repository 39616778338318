import { handleActions } from 'redux-actions';

import { createReducers, createState } from 'store/utils';
import { USER_AUTHORIZE } from './actions';

/**
 * User state
 */
export const userState = {
  ...createState('auth')
};

/**
 * User reducer
 */
export default handleActions({
  ...createReducers(USER_AUTHORIZE, 'auth')
},
userState);
