import { lazy } from 'react';

/**
 * All routes
 */
export default [
  {
    path: '/',
    component: lazy(() => import('components/views/home/Home')),
    title: 'Home',
    exact: true
  },
  {
    path: '/auth',
    component: lazy(() => import('components/views/auth/Auth')),
    title: 'Auth',
    exact: false,
    routes: [
      {
        path: '/login',
        component: lazy(() => import('components/views/auth/Login')),
        title: 'Login',
        exact: true
      },
      {
        path: '/logout',
        component: lazy(() => import('components/views/auth/Logout')),
        title: 'Logout',
        exact: true
      },
      {
        path: '/register',
        component: lazy(() => import('components/views/auth/Register')),
        title: 'Register',
        exact: true
      },
      {
        path: '/reset',
        component: lazy(() => import('components/views/auth/ResetPassword')),
        title: 'Reset password',
        exact: true
      }
    ]
  },
  {
    path: '/admin',
    component: lazy(() => import('components/views/admin/Admin')),
    title: 'Admin',
    exact: false,
    routes: [
      {
        path: '/account',
        component: lazy(() => import('components/views/admin/users/Account')),
        title: 'My account',
        exact: true
      },
      {
        path: '/customers',
        component: lazy(() => import('components/views/admin/organizations/Organizations')),
        title: 'Customers',
        exact: true
      },
      {
        path: '/groups',
        component: lazy(() => import('components/views/admin/references/References')),
        title: 'Groups',
        exact: true
      },
      {
        path: '/groups/:group',
        component: lazy(() => import('components/views/admin/references/Reference')),
        title: 'Group',
        exact: false,
        routes: [
          {
            path: '/users',
            component: lazy(() => import('components/views/admin/users/Users')),
            title: 'Users',
            exact: true,
            tab: 'users'
          },
          {
            path: '/subgroups',
            component: lazy(() => import('components/views/admin/groups/Groups')),
            title: 'Subgroups',
            exact: true,
            tab: 'subgroups'
          }
        ]
      },
      {
        path: '/subgroups',
        component: lazy(() => import('components/views/admin/groups/Groups')),
        title: 'Subgroups',
        exact: true,
        tab: 'subgroups'
      },
      {
        path: '/subgroups/:subgroup',
        component: lazy(() => import('components/views/admin/groups/Group')),
        title: 'Subgroup',
        exact: false,
        routes: [
          {
            path: '/users',
            component: lazy(() => import('components/views/admin/users/Users')),
            title: 'Users',
            exact: true,
            tab: 'users'
          },
          {
            path: '/locations',
            component: lazy(() => import('components/views/admin/locations/Locations')),
            title: 'Locations',
            exact: true,
            tab: 'locations'
          },
          {
            path: '/locations/:location',
            component: lazy(() => import('components/views/admin/locations/Location')),
            title: 'Location',
            exact: false,
            routes: [
              {
                path: '/machines',
                component: lazy(() => import('components/views/admin/machines/Machines')),
                title: 'Machines',
                exact: true,
                tab: 'machines'
              },
              {
                path: '/machines/:machine',
                component: lazy(() => import('components/views/admin/machines/Machine')),
                title: 'Machine',
                exact: true
              }
            ]
          }
        ]
      },
      {
        path: '/machines',
        component: lazy(() => import('components/views/admin/machines/Machines')),
        title: 'Machines',
        exact: true,
      },
      {
        path: '/machines/:machine',
        component: lazy(() => import('components/views/admin/machines/Machine')),
        title: 'Machine',
        exact: true
      },
      {
        path: '/users',
        component: lazy(() => import('components/views/admin/users/Users')),
        title: 'Users',
        exact: true
      },
      {
        path: '/users/:user',
        component: lazy(() => import('components/views/admin/users/User')),
        title: 'User',
        exact: true
      },
      {
        path: '/registrations',
        component: lazy(() => import('components/views/admin/registrations/Registrations')),
        title: 'Registrations',
        exact: true
      },
      {
        path: '/orders',
        component: lazy(() => import('components/views/admin/orders/Orders')),
        title: 'Orders',
        exact: true
      },
    ]
  },
  {
    path: '/machines',
    component: lazy(() => import('components/views/machine/Machine')),
    title: 'Machine',
    exact: false,
    routes: [
      {
        path: '/',
        component: lazy(() => import('components/views/machine/Machines')),
        title: 'Machines',
        exact: true
      },
      {
        path: '/:machine',
        component: lazy(() => import('components/views/machine/Info')),
        title: 'Machine Info',
        exact: true
      },
      {
        path: '/:machine/rent',
        component: lazy(() => import('components/views/machine/Rent')),
        title: 'Rent Machine',
        exact: true
      }
    ]
  },
  {
    path: '/products/:product',
    component: lazy(() => import('components/views/product/Product')),
    title: 'Product',
    exact: false,
  },
  {
    path: '/download',
    component: lazy(() => import('components/views/download/DownloadApp')),
    title: 'Download App'
  },
  {
    path: '/privacy',
    component: lazy(() => import('components/views/legal/Privacy')),
    title: 'Privacy Policy',
    exact: true,
  },
  {
    path: '*',
    component: lazy(() => import('components/views/error/NotFound')),
    title: 'Page not found'
  }
];
