import isUndefined from 'lodash/isUndefined';
import remove from 'lodash/remove';

/**
 * Core Listener
 */
export default class Listener {

  constructor() {
    this.index = 0;
    this.listeners = {};
  }

  /**
   * Fire an event
   */
  fire(name, args = []) {
    (this.listeners[name] || []).forEach((listener) => {
      listener(...args);
    });
  }

  /**
   * On event
   */
  on(name, callback) {
    if (isUndefined(this.listeners[name])) {
      this.listeners[name] = [];
    }
    callback.index = ++this.index;
    this.listeners[name].push(callback);
    return () => {
      remove((this.listeners[name] || []), (listener) => (
        listener.index === callback.index
      ));
    };
  }
}
