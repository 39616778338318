/**
 * Configuration
 */
export default {
  api: {
    root: process.env.REACT_APP_API_ROOT,
    services: {
      core: process.env.REACT_APP_API_CORE,
      group: process.env.REACT_APP_API_GROUP,
      location: process.env.REACT_APP_API_LOCATION,
      machine: process.env.REACT_APP_API_MACHINE,
      notification: process.env.REACT_APP_API_NOTIFICATION,
      order: process.env.REACT_APP_API_ORDER,
      organization: process.env.REACT_APP_API_ORGANIZATION,
      subscription: process.env.REACT_APP_API_SUBSCRIPTION,
      system: process.env.REACT_APP_API_SYSTEM,
      user: process.env.REACT_APP_API_USER,
    }
  },
  auth: {
    cognito: {
      app_id: process.env.REACT_APP_COGNITO_APP_ID,
      pool_id: process.env.REACT_APP_COGNITO_POOL_ID,
      region: process.env.REACT_APP_COGNITO_REGION || 'eu-central-1'
    },
    cookie: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: (process.env.REACT_APP_COOKIE_SECURE === '1')
    }
  },
  contentful:{
    base_url: process.env.REACT_APP_CONTENTFUL_API_BASE_URL,
    authorization: process.env.REACT_APP_CONTENTFUL_API_AUTHORIZATION,
  },
  breakpoints: {
    desktopWide: {
      width: 1440
    },
    desktop: {
      width: 1280
    },
    laptop: {
      width: 1024
    },
    tablet: {
      width: 768
    },
    mobile: {
      width: 360
    }
  },
  drawer: {
    width: 300
  },
  redux: {
    logger: false
  }
};
