import { handleActions } from 'redux-actions';

// import { createReducers, createState } from 'store/utils';

/**
 * Location state
 */
export const locationState = {

};

/**
 * Location reducer
 */
export default handleActions({

},
locationState);
