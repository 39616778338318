import isNull from 'lodash/isNull';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { drawerToggle, headerAssign } from 'components/app/actions';
import { redirect } from 'routes';
import { usePostMessage } from 'utils';
import useStyles from './styles';

/**
 * Header component
 */
export default function Header() {
  const dispatch = useDispatch();
  const postMessage = usePostMessage();
  const classes = useStyles();
  const { header, drawer } = useSelector(({ app }) => ({
    header: app.header,
    drawer: app.drawer
  }));
  const { open, active: drawerActive } = drawer;
  const { active, back, options, title, update } = header;
  const goBack = useCallback(() => {
    redirect(back);
  }, [back]);

  const toggleDrawer = useCallback(() => {
    if (!postMessage({ action: 'toggleMenu' })) {
      dispatch(drawerToggle(true));
    }
  }, [dispatch, postMessage]);

  const activateUpdate = useCallback(() => {
    dispatch(headerAssign({
      update: true
    }));
  }, [dispatch]);
  if (!active) {
    return <></>;
  }
  return (
    <AppBar
      position="sticky"
      variant="outlined"
      className={clsx({
        [classes.appBar]: true,
        [classes.appBarShift]: open || drawerActive
      })}
    >
      <Toolbar>
        {(back ?
          <IconButton
            className={classes.iconBtn}
            edge="start"
            onClick={goBack}
          >
            <Icon>arrow_back</Icon>
          </IconButton> :
          <IconButton
            className={classes.menuBtn}
            edge="start"
            onClick={toggleDrawer}
          >
            <Icon>menu</Icon>
          </IconButton>
        )}
        <Typography
          className={clsx(classes.title, {
            [classes.withOptions]: !isNull(options),
            [classes.withUpdate]: !isNull(update)
          })}
          align="left"
          variant="body1"
        >
          <span>{title}</span>
        </Typography>
        {!isNull(update) &&
          <IconButton
            className={classes.iconBtn}
            edge="start"
            onClick={activateUpdate}
          >
            <Icon>edit</Icon>
          </IconButton>
        }
      </Toolbar>
    </AppBar>
  );
}
