import { combineReducers } from 'redux';

import core from './core/reducers';
import group from './group/reducers';
import location from './location/reducers';
import machine from './machine/reducers';
import user from './user/reducers';

export default combineReducers({
  core,
  group,
  location,
  machine,
  user
});
