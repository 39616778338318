import faker from 'faker';
import random from 'lodash/random';
import remove from 'lodash/remove';
import sample from 'lodash/sample';
import sampleSize from 'lodash/sampleSize';

import Factory from 'services/core/Factory';

/**
 * User factory
 */
export default class User extends Factory {

  /**
   * Delete
   */
  async delete(type, filter) {
    await this.delay();
    switch (type) {
      case 'users':
        const users = this.seed('users');
        const user = users.find(this.getFilter(type, filter));
        if (user) {
          const locationId = parseInt(filter.location || '0');
          if (locationId) {
            const location = this.getFactory('location').seed('locations').find((item) => (
              item.id === locationId
            ));
            if (location) {
              location.users--;
            }
            remove(user.locations, (id) => (
              id === locationId
            ));
          }
          const groupId = parseInt(filter.group || '0');
          if (groupId) {
            const group = this.getFactory('group').seed('groups').find((item) => (
              item.id === groupId
            ));
            if (group) {
              group.users_count--;
            }
            remove(user.groups, (id) => (
              id === groupId
            ));
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * Filter
   */
  filter(type, user, query = {}) {
    switch (type) {
      case 'users':
        if (query.id) {
          return user.id === parseInt(query.id || '0');
        } else if (query.location) {
          return (user.locations || []).includes(parseInt(query.location || '0'));
        } else if (query.group) {
          return (user.groups || []).includes(parseInt(query.group || '0'));
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  /**
   * Generate single data
   */
  generate(type, id) {
    const groups = sampleSize(this.getFactory('group').seed('groups'), random(1, 5));
    const locations = sampleSize(this.getFactory('location').seed('locations'), random(1, 3));
    switch (type) {
      case 'users':
        return {
          id,
          email: faker.internet.email(),
          name: `${faker.name.firstName()} ${faker.name.lastName()}`,
          phone: faker.phone.phoneNumber(),
          role: sample(['admin', 'member']),
          locations: locations.map((location) => {
            location.users++;
            return location.id
          }),
          groups: groups.map((group) => {
            group.users_count++;
            return group.id;
          })
        };
      default:
        return {};
    }
  }
}
