import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const colors = {
  black: '#000000',
  grey: '#6f6f6e',
  white: '#ffffff'
};

export const fontWeight = {
  regular: '400',
  bold: '700',
  black: '900'
};

/**
 * Main theme
 */
export default createMuiTheme({
  palette: {
    primary: {
      accent: process.env.REACT_APP_THEME_PRIMARY_ACCENT || '#f9461c',
      main: process.env.REACT_APP_THEME_PRIMARY_MAIN || '#ed3a0a'
    },
    secondary: {
      main: colors.white
    }
  },
  typography: {
    h3: {
      fontWeight: fontWeight.black
    },
    h4: {
      fontWeight: fontWeight.black
    },
    h5: {
      fontStyle: 'italic',
      fontWeight: fontWeight.black,
      lineHeight: '100%'
    },
    h6: {
      fontWeight: fontWeight.bold,
      lineHeight: '100%'
    },
    subtitle1: {
      lineHeight: '120%'
    },
    subtitle2: {
    },
    body1: {
      lineHeight: '100%',
      fontWeight: fontWeight.bold
    },
    body2: {
      lineHeight: '100%',
      fontSize: '16px',
    },
    button: {
      fontWeight: fontWeight.bold
    }
  }
});
