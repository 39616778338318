import { bind } from 'utils';
import API from '../API';
import Factory from './Factory';

/**
 * Machine API
 */
export default class Machine {

  constructor() {
    this.api = new API('machine');
    this.factory = new Factory(this.api);
    bind(this, [
      'deleteMachine',
      'getMachine',
      'getMachines',
      'getVariants'
    ]);
  }

  /**
   * Delete machine
   */
  deleteMachine({ group, id, location }) {
    return this.api.delete(`groups/${group}/locations/${location}/machines/${id}`);
  }

  /**
   * Get single machine
   */
  getMachine({ id }) {
    return this.api.get(`machines/${id}`);
  }

  /**
   * Get machines
   */
  getMachines({ ...query }) {
    return this.api.navigate(`machines`, query);
  }

  /**
   * Get variants
   */
  getVariants({ ...query }) {
    return this.api.navigate(`variants`, query);
  }
}
