import { useCallback, useEffect } from 'react';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import { useSelector } from 'react-redux';

/**
 * Bind methods
 */
export function bind(object, methods = []) {
  (methods || Object.getOwnPropertyNames(object.constructor.prototype)).forEach((key) => {
    if (key !== 'constructor' && isFunction(object[key])) {
      object[key] = object[key].bind(object);
    }
  });
  return object;
}

/**
 * Delay
 */
export function delay(timeout = 200, data = null) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, timeout);
  });
}

/**
 * Extract
 */
export function extract(object, fields) {
  return fromPairs(keys(fields).map((field) => [
    field,
    get(object, fields[field] + '')]
  ));
}

/**
 * Stop propagation on event
 */
export function freeze(callback, ...args) {
  return (e) => {
    e.stopPropagation();
    if (isFunction(callback)) {
      return callback(...args, e);
    }
  };
}

/**
 * Query string to object
 */
export function fromQuery(url) {
  const question = url.indexOf('?');
  if (question < 0) {
    return {};
  }
  const object = {},
        hash = url.indexOf('#', question),
        query = url.substring(question + 1, (hash < 0) ? undefined : hash);
  query.split('&').forEach((pair) => {
    const [name, value] = pair.split('=');
    object[name] = isUndefined(value) ? true : decodeURIComponent(value);
  });
  return object;
}

/**
 * Identify correct fallback
 */
export function identifyFallback(values = [], index = 0) {
  if (index >= values.length) {
    return undefined;
  }
  if (isUndefined(values[index])) {
    return identifyFallback(values, index + 1);
  }
  return values[index];
}

/**
 * Listen for an event
 */
export function listen(element, names, callback, capture = false) {
  const callbacks = [];
  if (!isArray(names)) {
    names = [names];
  }
  (names = [...names]).forEach((name) => {
    callbacks.push((...args) => {
      callback(...args);
    });
    element.addEventListener(name, callbacks[callbacks.length - 1], capture);
  });
  return () => {
    names.forEach((name, index) => {
      element.removeEventListener(name, callbacks[index], capture);
    });
  };
}

/**
 * Wait for an event once
 */
export function once(element, name, timeout = false) {
  let unlisten = null;
  const promise = new Promise((resolve, reject) => {
    let completed = false;
    unlisten = listen(element, name, (e) => {
      if (e.target === element) {
        if (!completed) {
          completed = true;
          unlisten();
          resolve(e);
        }
      }
    });
    if (timeout !== false) {
      setTimeout(() => {
        if (!completed) {
          completed = true;
          unlisten();
          reject('Timeout');
        }
      }, timeout);
    }
  });
  promise.unlisten = unlisten;
  return promise;
}

/**
 * Pick safe
 */
export function pickSafe(object, fields) {
  return fromPairs(fields.map((field) => (
    [field, object[field]]
  )).filter((pair) => (
    !isUndefined(pair[1]) && (pair[1] !== '') && (pair[1] !== null)
  )))
}

/**
 * Object to query string
 */
export function toQuery(object) {
  const pairs = [];
  keys(object).forEach((name) => {
    const value = object[name];
    if (value === false) {
      return;
    } else if (isUndefined(value) || (value === true)) {
      pairs.push(name);
    } else {
      pairs.push(name + '=' + encodeURIComponent(value));
    }
  });
  return pairs.length ? ('?' + pairs.join('&')) : '';
}

/**
 * sprintf
 */
export function sprintf(format, ...args) {
  let i = 0;
  return format.replace(/%s/g, () => (
    args[i++]
  ));
}

/**
 * To price
 */
export function toPrice(value, symbol = '€') {
  return (parseFloat(value).toFixed(2).replace('.', ',') + '')
    .replace(/[^0-9,]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ' + symbol;
}

/**
 * Use app log
 */
export function useAppLog() {
  const postMessage = usePostMessage();
  return (...args) => {
    postMessage({
      action: 'log',
      args
    });
  };
}

/**
 * On message
 */
export function useOnMessage(callback) {
  return useEffect(() => {
    if (isFunction(callback)) {
      const onMessage = (event) => {
        const { action, args = [] } = JSON.parse(event?.data || '{}');
        callback(action, ...args);
      };
      window.document.addEventListener('message', onMessage);
      return () => {
        window.document.removeEventListener('message', onMessage);
      };
    }
  }, [callback]);
}

/**
 * Use message sender to app
 * Returns a callback that accepts an object to send to app
 * Callback returns a boolean, true if message is sent
 */
export function usePostMessage() {
  const webview = useWebView();
  return useCallback((data = {}, event) => {
    if (webview && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      return true;
    }
    return false;
  }, [webview]);
}

/**
 * Is webview
 */
export function useWebView() {
  const { webview } = useSelector(({ app }) => ({
    webview: app.webview
  }));
  return !!webview;
}
