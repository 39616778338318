import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";

import * as serviceWorker from 'serviceWorker';
import { wrap } from 'store';
import App from 'components/app/App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://aaa2e105f0274153990797e63e0b4a35@o125054.ingest.sentry.io/5515184'
  });
}


export const root = document.getElementById('root');

ReactDOM.render(wrap(<App />), root);
serviceWorker.unregister();
