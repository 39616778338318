import { bind } from 'utils';
import auth from 'services/auth';
import API from '../API';
import Factory from './Factory';

/**
 * User API
 */
export default class User {

  constructor() {
    this.api = new API('user');
    this.factory = new Factory(this.api);
    bind(this, [
      'authorize',
      'deleteUser',
      'getMe',
      'getRegistrations',
      'getUser',
      'getUsers',
      'postRegistrations',
      'postUser',
      'putMe',
      'putRegistrations',
      'putUser'
    ]);
  }

  /**
   * Authorize
   */
  async authorize() {
    try {
      await auth.refresh();
      if (auth.token) {
        return await this.getMe();
      }
    } catch (exception) {
      return {};
    }
  }

  /**
   * Delete user
   */
  deleteUser({ group, id, reference, request = false }) {
    if (reference) {
      return this.api.delete(`references/${reference}/users/${id}`);
    } else if (group) {
      return this.api.delete(`groups/${group}/users/${id}`);
    } else {
      return this.api.delete(`users/${id}${request ? '?request=1' : ''}`);
    }
  }

  /**
   * Get current user
   */
  getMe() {
    return this.api.get('me');
  }

  /**
   * Get registrations
   */
  getRegistrations(query) {
    return this.api.navigate(`registrations`, query);
  }

  /**
   * Get single user
   */
  getUser({ group, reference, id }) {
    if (group) {
      return this.api.get(`groups/${group}/users/${id}`);
    } else if (reference) {
      return this.api.get(`references/${reference}/users/${id}`);
    } else {
      return this.api.get(`users/${id}`);
    }
  }

  /**
   * Get users
   */
  getUsers({ group, reference, ...query }) {
    if (group) {
      return this.api.navigate(`groups/${group}/users`, query);
    } else if (reference) {
      return this.api.navigate(`references/${reference}/users`, query);
    } else {
      return this.api.navigate(`users`, query);
    }
  }

  /**
   * Register
   */
  postRegistrations(inputs) {
    return this.api.post('registrations', inputs);
  }

  /**
   * Post user
   */
  postUser({ group, reference, ...inputs }) {
    if (group) {
      return this.api.post(`groups/${group}/users`, inputs);
    } else if (reference) {
      return this.api.post(`references/${reference}/users`, inputs);
    } else {
      return this.api.post('users', inputs);
    }
  }

  /**
   * Update account
   */
  putMe(inputs) {
    return this.api.put('me', inputs);
  }

  /**
   * Put Registration
   */
  putRegistrations({ id, ...inputs }) {
    return this.api.put(`registrations/${id}`, inputs);
  }

  /**
   * Update user
   */
  putUser({ id, ...inputs }) {
    return this.api.put(`users/${id}`, inputs);
  }
}
