import isUndefined from 'lodash/isUndefined';
import axios from 'axios';

import config from 'config';
import { bind, fromQuery, toQuery } from 'utils';
import auth from 'services/auth';

const ASSUME_USER_HEADER = 'X-Amz-Security-Token';

/**
 * API methods
 */
export default class API {

  constructor(service) {
    this.cleanup = [];
    this.service = service;
    bind(this, [
      'delete',
      'get',
      'post',
      'put'
    ]);
    this.init();
  }

  /**
   * Get root
   */
  get root() {
    return config.api.root || config.api.services[this.service] || '';
  }

  /**
   * Delete
   */
  delete(...args) {
    return this.axios.delete(...args);
  }

  /**
   * Destroy API
   */
  destroy() {
    this.cleanup.forEach((cleanup) => {
      cleanup();
    });
  }

  /**
   * Get
   */
  async get(...args) {
    const { data } = await this.axios.get(...args);
    return data;
  }

  /**
   * Initialize
   */
  init() {
    this.axios = axios.create({
      baseURL: this.root
    });
    this.cleanup.push(auth.on('refresh', (token, user) => {
      this.axios.defaults.headers['Authorization'] = token;
      if (process.env.REACT_APP_BYPASS_USER && user) {
        this.axios.defaults.headers['Bypass-User'] = user;
      }
      const assumed = getAssumedUser();
      if (assumed) {
        this.axios.defaults.headers[ASSUME_USER_HEADER] = assumed;
      }
    }));
    this.cleanup.push(auth.on('destroy', () => {
      if (!isUndefined(this.axios.defaults.headers['Authorization'])) {
        delete this.axios.defaults.headers['Authorization'];
        if (process.env.REACT_APP_BYPASS_USER) {
          delete this.axios.defaults.headers['Bypass-User'];
        }
        const assumed = getAssumedUser();
        if (assumed) {
          delete this.axios.defaults.headers[ASSUME_USER_HEADER];
        }
      }
    }));
  }

  /**
   * Navigate
   */
  async navigate(endpoint, query, config) {
    const { data = {} } = await this.axios.get(endpoint + toQuery(query), config);
    return {
      count: 0,
      results: [],
      ...data
    };
  }

  /**
   * Post
   */
  async post(...args) {
    const { data } = await this.axios.post(...args);
    return data;
  }

  /**
   * Put
   */
  async put(...args) {
    const { data } = await this.axios.put(...args);
    return data;
  }
}

function getAssumedUser() {
  const query = fromQuery(window.location.search);
  return query['assume-user'];
}
