import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Logo from 'components/helpers/Logo';
import Header from 'components/sections/header/Header';
import useStyles from './styles';

export const playStoreLink = 'https://play.google.com/store/apps/details?id=fi.hako.huoleton&hl=en';
export const appStoreLink = 'https://apps.apple.com/ph/app/hako-huoleton/id1565624285';
/**
 * DowloadApp page
 */
export default function DowloadApp() {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        className={styles.gridContainer}
      >
        <Header />
        <Grid
          item
          xs={12}
          className={styles.logoContainer}
        >
          <Logo type="download" />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignContent="space-between"
            justify="center"
            spacing={1}
          >
            <Grid item>
              <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
                <img
                  src="/assets/images/google-play-store.png"
                  alt="play store"
                  className={styles.avatar}
                />
              </a>
            </Grid>
            <Grid item>
              <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                <img
                  src="/assets/images/app-store-us.png"
                  alt="app store"
                  className={styles.avatar}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
